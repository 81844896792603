import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DetailView from "@/views/DetailView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/deploy',
    name: 'deploy',
    component: () => import(/* webpackChunkName: "about" */ '../views/DeployView.vue')
  },
  {
    path: '/launch',
    name: 'launch',
    component: () => import(/* webpackChunkName: "about" */ '../views/LaunchView.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: DetailView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
