<script>
// import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeadComponent.vue";
// import Pagination from "@/components/Pagination.vue";
import axios from "@/utils/axios";
import {formatNumber, minZero, short_address, timeAgo} from "@/utils/utils";
import {ElMessage} from "element-plus";
import {ethers} from "ethers";
import {LaunchPadAbi} from "@/store/LaunchPadAbi";
import {launchpad_address, uni_factory_address, uni_router_address, weth_address} from "@/store";
import {mapState} from "vuex";
import {getSigner} from "@/utils/wallet";
import {TokenAbi} from "@/store/TokenAbi";
import {createChart} from "lightweight-charts";
import {UniswapV2FactoryAbi} from "@/store/UniswapV2FactoryAbi";
import {UniswapV2Router02Abi} from "@/store/UniswapV2Router02Abi";
import {ChainId, CurrencyAmount, Percent, Token, TradeType} from "@uniswap/sdk-core";
import {PairAbi} from "@/store/PairAbi";
import {Pair, Route, Trade} from "@uniswap/v2-sdk";
import {default_factory, default_provider, default_uni_factory_contract} from "@/utils/ethRpc";
import HeadComponent from "@/components/HeadComponent.vue";

export default {
  name: "DetailView",
  components: { HeadComponent, },
  data: function () {
    return {
      token: {
        name: 'Loading...',
        symbol: 'LD',
        creatorAddress: 'N/A',
        iconUrl: '',
        marketCap: 0,
        bondingCurveProgress: 0,
        remain: 0,
        collateral: 0,
        state: '',
        virtualLiquidity: 0,
        VolumeIn24: 0,
        rise: 0,
        price_in_r64: 0,
        launch_progress: 0,
        image_url: '',
        pair_address: null,
        history_list: [],
      },
      can_buy_amount: 0,
      can_sell_amount: 0,
      comment: '', // New property for comment input
      commentLength: 0, // To track the length of the comment
      maxCommentLength: 256, // Maximum allowed length
      comments: [], // To hold submitted comments
      tab: 'buy',
      amount: 0,
      token_amount: 0,
      currentPage: 1,
      totalPages: 10,
      token_address: "",
      eth_balance: 0,
      token_balance: 0,
      slippage_tolerance: 5,
      slippage_tolerance_temp: '',
      eth_price: 0,
      holders: [],
      isVisible: false,
      tab2: 'comment',
      loading: false,
      loading_amount: false,
      history_list: [],
    };
  },
  computed: {
    ...mapState(['wallet_address', 'wallet_type']), // 映射 state
  },
  methods: {
    minZero,
    formatNumber,
    timeAgo,
    short_address,
    async outerBuy() {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information

        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const decimals = 18;

        const uni_factory_contract = new ethers.Contract(uni_factory_address, UniswapV2FactoryAbi, signer);
        const pair_address = await uni_factory_contract.getPair(weth_address, this.token_address);
        console.log('pair_address', pair_address);


        const weth = new Token(ChainId.BNB, weth_address, decimals, "weth", "weth");
        const unknown = new Token(ChainId.BNB, this.token_address, decimals, this.token.symbol, this.token.name);

        const pairContract = new ethers.Contract(pair_address, PairAbi, signer);
        const reserves = await pairContract.getReserves()
        let reserve_weth, reserve_token;
        if (this.token.token0_address === weth_address) {
          reserve_weth = reserves[0];
          reserve_token = reserves[1];
        } else {
          reserve_token = reserves[0];
          reserve_weth = reserves[1];
        }
        console.log('reserves', reserves)

        const WETH_UNKNOWN = new Pair(
            CurrencyAmount.fromRawAmount(weth, reserve_weth.toString()),
            CurrencyAmount.fromRawAmount(unknown, reserve_token.toString())
        );

        const WETH_TO_UNKNOWN = new Route([WETH_UNKNOWN], weth, unknown);
        const trade = new Trade(WETH_TO_UNKNOWN, CurrencyAmount.fromRawAmount(weth, ethers.parseEther(this.amount.toString()).toString()), TradeType.EXACT_INPUT);

        console.log('trade', trade);

        // Set slippage tolerance to 5%
        const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
        const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
        const amountOut = ethers.parseUnits(minimumAmountOut.toSignificant(18), decimals); // Amount of tokens to receive

        const path = [weth_address, this.token_address];

        // Initiate the transaction
        const router = new ethers.Contract(uni_router_address, UniswapV2Router02Abi, signer);

        console.log('params', [
          amountOut,
          path,
          this.wallet_address,
          Math.floor(Date.now() / 1000) + 60 * 20,
          {
            value: ethers.parseEther(this.amount.toString()).toString()
          }
        ]);

        const tx = await router.swapExactETHForTokens(
            amountOut,
            path,
            this.wallet_address,
            Math.floor(Date.now() / 1000) + 60 * 20,
            {
              value: ethers.parseEther(this.amount.toString()).toString()
            }
        );

        return tx;

      } catch (e) {
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
      }
    },
    async outerSell() {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        let provider, signer;

        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const decimals = 18;
        const uni_factory_contract = new ethers.Contract(uni_factory_address, UniswapV2FactoryAbi, signer);
        const pair_address = await uni_factory_contract.getPair(this.token_address, weth_address);
        console.log('pair_address', pair_address);

        const token_contract = new ethers.Contract(this.token_address, TokenAbi, signer);
        const token_balance = await token_contract.balanceOf(this.wallet_address);
        console.log("token_balance", token_balance)

        // Check allowance
        const router = new ethers.Contract(uni_router_address, UniswapV2Router02Abi, signer);
        const allowance = await token_contract.allowance(this.wallet_address, uni_router_address);
        console.log("allowance", allowance)

        const weth = new Token(ChainId.BNB, weth_address, decimals, "weth", "weth");
        const unknown = new Token(ChainId.BNB, this.token_address, decimals, this.token.symbol, this.token.name);

        if ((Number)(ethers.formatEther(allowance).toString()) < this.token_amount) {
          // If allowance is insufficient, approve the router
          console.log("Approval")
          const txApprove = await token_contract.approve(uni_router_address, ethers.MaxUint256);
          console.log('Approval transaction hash:', txApprove.hash);
          ElMessage.success('Approval submitted: ' + txApprove.hash);
          await txApprove.wait(); // Wait for the approval transaction to be mined
          console.log('Approval confirmed');
          ElMessage.success('Approval confirmed');
        }

        const pairContract = new ethers.Contract(pair_address, PairAbi, signer);
        const reserves = await pairContract.getReserves();
        let reserve_weth, reserve_token;
        if (this.token.token0_address === weth_address) {
          console.log("asdasda")
          reserve_weth = reserves[0];
          reserve_token = reserves[1];
        } else {
          reserve_token = reserves[0];
          reserve_weth = reserves[1];
        }

        console.log('reserves', reserves);

        const TOKEN_WETH = new Pair(
            CurrencyAmount.fromRawAmount(unknown, reserve_token.toString()),
            CurrencyAmount.fromRawAmount(weth, reserve_weth.toString())
        );

        const TOKEN_TO_WETH = new Route([TOKEN_WETH], unknown, weth);
        const token_in = ethers.parseEther(this.token_amount.toString()).toString();
        console.log('token_in', token_in);
        const trade = new Trade(TOKEN_TO_WETH, CurrencyAmount.fromRawAmount(unknown, token_in), TradeType.EXACT_INPUT);

        console.log('trade', trade);

        // Set slippage tolerance to 5%
        const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
        const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
        const amountOut = ethers.parseEther(minimumAmountOut.toExact()); // Amount of WETH to receive

        console.log('amountOut', minimumAmountOut.toExact() + " bnb");

        const path = [this.token_address, weth_address];

        // Initiate the transaction
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time

        console.log('params', [
          token_in,
          amountOut,
          path,
          this.wallet_address,
          deadline,
          {}
        ]);

        const tx = await router.swapExactTokensForETH(
            token_in,
            amountOut,
            path,
            this.wallet_address,
            deadline,
            {
              // gasLimit: 1_000_000_000
            }
        );

        return tx;

      } catch (e) {
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
      }
    },
    handleCommentInput(event) {
      this.comment = event.target.value;
      this.commentLength = this.comment.length; // Update comment length
    },
    onClickPercent(pct) {
      if (pct === 100) {
        pct = 99;
      }
      this.token_amount = Math.ceil(this.token_balance * pct / 100);
      this.onChangeAmount()
    },
    onClickAmount(amt) {
      this.amount = amt;
      this.onChangeAmount()
    },
    onClickSelectTab(tab) {
      this.tab = tab
    },
    handlePageChange(num) {
      console.log('handlePageChange', num)
    },
    async load(address) {
      const load_resp = await axios.get("/api/token/" + address);
      if (load_resp.data.code === 0) {
        this.token = load_resp.data.data.token;
        this.eth_price = load_resp.data.data.eth_price;

      }
    },
    async loadKline() {
      const url = "/api/kline/" + this.token_address + "?duration=" + 60 * 15 + "&amount=25"
      const load_price_resp = await axios.get(url);
      this.render(load_price_resp.data.data.klines)
    },
    async loadComment(address) {
      const load_resp = await axios.post("/api/comment_list", {
        page: this.page,
        pageSize: this.pageSize,
        tokenAddress: this.token_address
      });
      if (load_resp.data.code === 0) {
        this.comments = load_resp.data.data.comments;
      }
    },
    async loadHistory(address) {
      const load_resp = await axios.post("/api/history_list", {
        page: this.page,
        pageSize: this.pageSize,
        tokenAddress: this.token_address
      });
      if (load_resp.data.code === 0) {
        this.history_list = load_resp.data.data.histories;
      }
    },
    async loadHolder(tokenAddress) {
      const url = '/api/holder/' + tokenAddress;
      const resp = await axios.get(url);
      if (resp.data.code === 0) {
        this.holders = resp.data.data;
      }
    },
    async submitComment() {
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }
      if (this.commentLength > 0 && this.commentLength <= this.maxCommentLength) {
        // Handle comment submission (e.g., send to an API)
        console.log("Comment submitted:", this.comment);

        await axios.post('/api/create_comment', {
          tokenAddress: this.token_address,
          creatorAddress: this.wallet_address,
          content: this.comment
        })

        this.comments.push({creatorAddress: this.wallet_address, content: "Just now", text: this.comment}); // Example
        this.comment = ''; // Clear the comment after submission
        this.commentLength = 0; // Reset length


      } else {
        ElMessage.error(`Comment must be between 1 and ${this.maxCommentLength} characters.`);
      }
    },
    async loadErc20Balance(tokenAddress) {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information

        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        // Create a contract instance for the ERC20 token
        const erc20Abi = [
          // Only include the balanceOf function in the ABI
          "function balanceOf(address owner) view returns (uint256)"
        ];
        const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider);

        // Fetch the ERC20 balance
        const balanceBigNumber = await tokenContract.balanceOf(this.wallet_address);
        const balance = ethers.formatUnits(balanceBigNumber, 18); // Format balance to token's decimal (assuming 18 decimals)
        this.token_balance = Number(balance);

        // console.log('ERC20 Balance:', balance);
      } catch (error) {
        console.error('Error loading ERC20 balance:', error);
      }
    },
    render(data) {
      if (!this.$refs.chartContainer) {
        console.log('Render failed: chart container is not available.');
        return;
      }

      //获取this.$refs.chartContainer高度
      const height = this.$refs.chartContainer.clientHeight;
      console.log('this.$refs.chartContainer.clientHeight:', height);

      console.log('Rendering chart with data:', data);

      this.$refs.chartContainer.innerHTML = '';
      const chart = createChart(this.$refs.chartContainer, {
        width: this.$refs.chartContainer.clientWidth,
        height: this.$refs.chartContainer.clientHeight,
        layout: {
          background: {color: '#151718'},
          textColor: '#DDD',
        },
        timeScale: {
          // Adds hours and minutes to the chart.
          timeVisible: true,
          secondsVisible: false
        },
        localization: {
          priceFormatter: (p) => `${p.toFixed(15)}`,
        },
        grid: {
          vertLines: {color: '#444'},
          horzLines: {color: '#444'},
        },
        priceScale: {
          position: 'right',
          autoScale: false, // Disable auto scaling
          min: 1e-14,          // Set minimum value for y-axis
          max: 1e-15       // Set maximum value for y-axis
        },
        minimumWidth: 100,
        priceFormat: {
          minMove: 0.000000001,
          precision: 10,
        },
        debug: true,
      });

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: 'rgba(75, 192, 192, 1)',
        downColor: 'rgba(255, 99, 132, 1)',
        borderVisible: false,
      });

      const volumeSeries = chart.addHistogramSeries({
        color: '#26a69a',
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
      });

      try {
        let formt_data = data.map(item => ({
          time: Math.floor(Date.parse(item.open_time) / 1000), // Keep time as integer (optional)
          open: (Number)(item.open),
          high: (Number)(item.high),
          low: (Number)(item.low),
          close: (Number)(item.close),
          volume: (Number)(item.volume),
        }));
        candlestickSeries.setData(formt_data);
        //调整canvars高度
        chart.resize(this.$refs.chartContainer.clientWidth, height);
        //刷新时间
        // chart.timeScale().fitContent();
        console.log('Render successful', formt_data);
      } catch (error) {
        console.error('Render failed:', error);
      }
    },
    onClickCopyAddress() {
      window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText(this.token_address);
      ElMessage.success('Address copied to clipboard.');
    },
    async refreshCanBuyAmount() {
      if (this.amount <= 0) {
        this.can_buy_amount = 0;
        return
      }
      this.can_buy_amount = (Number)(await this.estimateBuy(this.token_address, this.amount.toString(), 0))
    },
    async refreshCanSellAmount() {
      if (this.token_amount <= 0) {
        this.can_sell_amount = 0;
        return
      }
      this.can_sell_amount = (Number)(await this.estimateSell(this.token_address, this.token_amount.toString(), 0))
    },
    async refreshCanBuyOnUni() {
      if (this.amount <= 0) {
        this.can_buy_amount = 0;
        return
      }
      const decimals = 18;
      const token_contract = new ethers.Contract(this.token_address, TokenAbi, default_provider);
      const pair_address = await default_uni_factory_contract.getPair(this.token_address, weth_address);
      console.log('pair_address', pair_address);

      const pairContract = new ethers.Contract(pair_address, PairAbi, default_provider);

      const reserves = await pairContract.getReserves();
      const reserve0 = reserves[0];
      const reserve1 = reserves[1];
      console.log('reserves', reserves);

      // Determine which reserve corresponds to which token
      const token0 = await pairContract.token0();
      // const token1 = await pairContract.token1();

      let tokenReserve, wethReserve;

      if (this.token_address.toLowerCase() === token0.toLowerCase()) {
        tokenReserve = reserve0;
        wethReserve = reserve1;
      } else {
        tokenReserve = reserve1;
        wethReserve = reserve0;
      }

      const weth = new Token(ChainId.BASE, weth_address, decimals, "weth", "weth");
      const unknown = new Token(ChainId.BASE, this.token_address, decimals, this.token.symbol, this.token.name);

      const WETH_TOKEN = new Pair(
          CurrencyAmount.fromRawAmount(weth, wethReserve.toString()),
          CurrencyAmount.fromRawAmount(unknown, tokenReserve.toString()),
      );


      const WETH_TO_TOKEN = new Route([WETH_TOKEN], weth, unknown);
      const eth_in = ethers.parseEther(this.amount.toString()).toString();
      console.log('eth_in', eth_in);
      const trade = new Trade(WETH_TO_TOKEN, CurrencyAmount.fromRawAmount(weth, eth_in), TradeType.EXACT_INPUT);

      const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
      const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
      // console.log('minimumAmountOut',minimumAmountOut)
      const amountOut = minimumAmountOut.toSignificant(6);
      this.can_buy_amount = (Number)(amountOut);
    },
    async refreshCanSellOnUni() {
      if (this.token_amount <= 0) {
        this.can_sell_amount = 0;
        return
      }
      const decimals = 18;
      const token_contract = new ethers.Contract(this.token_address, TokenAbi, default_provider);
      const pair_address = await default_uni_factory_contract.getPair(this.token_address, weth_address);
      console.log('pair_address', pair_address);

      const pairContract = new ethers.Contract(pair_address, PairAbi, default_provider);
      const reserves = await pairContract.getReserves();
      const reserve0 = reserves[0];
      const reserve1 = reserves[1];
      console.log('reserves', reserves);

      // Determine which reserve corresponds to which token
      const token0 = await pairContract.token0();
      // const token1 = await pairContract.token1();

      let tokenReserve, wethReserve;

      if (this.token_address.toLowerCase() === token0.toLowerCase()) {
        tokenReserve = reserve0;
        wethReserve = reserve1;
      } else {
        tokenReserve = reserve1;
        wethReserve = reserve0;
      }

      const weth = new Token(ChainId.BASE, weth_address, decimals, "weth", "weth");
      const unknown = new Token(ChainId.BASE, this.token_address, decimals, this.token.symbol, this.token.name);

      const TOKEN_WETH = new Pair(
          CurrencyAmount.fromRawAmount(unknown, tokenReserve.toString()),
          CurrencyAmount.fromRawAmount(weth, wethReserve.toString())
      );

      const TOKEN_TO_WETH = new Route([TOKEN_WETH], unknown, weth);
      const token_in = ethers.parseEther(this.token_amount.toString()).toString();
      console.log('token_in', token_in);
      const trade = new Trade(TOKEN_TO_WETH, CurrencyAmount.fromRawAmount(unknown, token_in), TradeType.EXACT_INPUT);

      const slippageTolerance = new Percent(this.slippage_tolerance.toString(), '100'); // 5% slippage
      const minimumAmountOut = trade.minimumAmountOut(slippageTolerance);
      // console.log('minimumAmountOut',minimumAmountOut)
      const amountOut = minimumAmountOut.toExact()
      this.can_sell_amount = (Number)(amountOut);
    },
    async onChangeAmount() {
      this.loading_amount = true;
      if (this.tab === 'buy' && !this.token.pair_address) {
        await this.refreshCanBuyAmount()
      }
      if (this.tab === 'sell' && !this.token.pair_address) {
        await this.refreshCanSellAmount()
      }
      if (this.tab === 'buy' && this.token.pair_address) {
        await this.refreshCanBuyOnUni()
      }
      if (this.tab === 'sell' && this.token.pair_address) {
        await this.refreshCanSellOnUni()
      }
      this.loading_amount = false;
    },
    Reciprocal(amount) {
      if (amount === 0) {
        return new Percent("100", "100")
      }
      const numerator = BigInt(Math.round((100 - amount) * 100));
      const denominator = BigInt(10000);
      const p = new Percent(numerator.toString(), denominator.toString());
      console.log("p (toFixed):", p.toFixed(2) + "%");
      console.log("p (toSignificant):", p.toSignificant(3) + "%");
      return p
    },
    async estimateSell(tokenAddress, tokenAmountIn, slippageTolerance) {
      const tokenAmountInBig = ethers.parseEther(tokenAmountIn).toString();
      console.log("tokenAmountInBig", tokenAmountInBig)
      try {
        const result = await default_factory.getBNBAmountBySaleWithFee(tokenAddress, tokenAmountInBig.toString())
        console.log("getBNBAmountBySaleWithFee result",result)
        const bnbAmount = result[0].toString()
        const percent = this.Reciprocal(slippageTolerance);
        const bnbToken = new Token(1, '0x1234567890123456789012345678901234567890', 18, 'BNB', 'WBNB');
        const currencyAmount = CurrencyAmount.fromRawAmount(bnbToken, bnbAmount)
        const amountOut = currencyAmount.multiply(percent).toExact()
        return amountOut;

      } catch (e) {
        console.error(e)
        return 0
      }
    },
    async estimateBuy(tokenAddress, bnbAmountIn, slippageTolerance) {
      const bnbAmountInBig = ethers.parseEther(bnbAmountIn)
      console.log("bnbAmountInBig", bnbAmountInBig.toString())
      try {

        const result = await default_factory.getTokenAmountByPurchaseWithFee(tokenAddress, bnbAmountInBig.toString());
        const tokenAmountRaw = result.tokenAmount.toString()
        const percent = this.Reciprocal(slippageTolerance);

        const token = new Token(1, '0x1234567890123456789012345678901234567890', 18, 'Token', 'Token');

        const currencyAmount = CurrencyAmount.fromRawAmount(token, tokenAmountRaw)
        console.log(`指定 18 位有效数字的金额: ${currencyAmount.toExact()} ${token.symbol}`);

        const amountOut = currencyAmount.multiply(percent).toExact()

        return amountOut

      } catch (e) {
        console.error(e)
        return 0;
      }

    },
    async onClickTrade() {
      console.log('onClickTrade', this.token_address)
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }

      if (this.loading) {
        return
      }
      this.loading = true

      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const factory = new ethers.Contract(launchpad_address, LaunchPadAbi, signer)

        if (this.token.pair_address) {
          if (this.tab === 'buy') {
            let tx = await this.outerBuy();
            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          } else {
            let tx = await this.outerSell()
            ElMessage.success('Transaction submitted: ' + tx.hash);
            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          }
        } else {


          if (this.tab === 'buy') {
            let min = await this.estimateBuy(this.token_address, this.amount.toString(), this.slippage_tolerance);
            let min2 = ethers.parseEther(min.toString()).toString();
            const tx = await factory.purchaseToken(this.token_address,
                min2
                , {
                  value: ethers.parseEther(this.amount.toString())
                });
            console.log('tx', tx.hash)
            ElMessage.success('Transaction submitted: ' + tx.hash);
            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          } else if (this.tab === 'sell') {

            const token_contract = new ethers.Contract(this.token_address, TokenAbi, signer);
            const allowance = await token_contract.allowance(this.wallet_address, launchpad_address);
            console.log("allowance", allowance)

            if ((Number)(ethers.formatEther(allowance).toString()) < this.token_amount) {

              const txApprove = await token_contract.approve(launchpad_address, ethers.MaxUint256);
              console.log('Approval transaction hash:', txApprove.hash);
              ElMessage.success('Approval submitted: ' + txApprove.hash);
              await txApprove.wait(); // Wait for the approval transaction to be mined
              console.log('Approval confirmed');
              ElMessage.success('Approval confirmed');

            }

            const amount_in_wei = ethers.parseEther(this.token_amount.toString()).toString();
            console.log('amount_in_wei', amount_in_wei)
            let min = await this.estimateSell(this.token_address, this.token_amount.toString(), this.slippage_tolerance);
            let min2 = ethers.parseEther(min.toString()).toString()
            const tx = await factory.saleToken(this.token_address, amount_in_wei, min2, {})
            ElMessage.success('Transaction submitted: ' + tx.hash);

            const receipt = await tx.wait();
            console.log('receipt', receipt)
            if (receipt.status === 1) {
              ElMessage.success('Transaction confirmed');
              await axios.post('/api/create_history', {
                tradeType: this.tab,
                tokenAddress: this.token_address,
                txid: tx.hash,
                amount: this.token_amount,
                state: this.token.state,
                creatorAddress: this.wallet_address
              })
            }
          }
        }
        this.loading = false;
      } catch (e) {
        // ElMessage.error('Error: ' + e);
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
        this.loading = false;
      } finally {
        this.loading = false
      }
    },
    async loadEthBalance() {
      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information

        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        if (!this.wallet_address) {
          throw new Error('Wallet address is not defined');
        }

        const balanceBigNumber = await provider.getBalance(this.wallet_address);
        const balance = ethers.formatEther(balanceBigNumber); // Format balance to Ether
        this.eth_balance = (Number)(balance);
        // console.log('BNB Balance:', balance);
      } catch (error) {
        console.error('Error loading ETH balance:', error);
      }
    },
    onClickSave() {
      this.slippage_tolerance = this.slippage_tolerance_temp;
      this.slippage_tolerance_temp = '';
    },
    len(l) {
      let a = 50 + (l / 2);
      return "width:" + a + '%'
    }
  },
  mounted() {
    this.token_address = this.$route.query.token;
    console.log('Retrieved ID:', this.token_address);

    this.load(this.token_address);
    this.loadKline(this.token_address)
    this.loadHolder(this.token_address);
    this.loadComment(this.token_address);
    this.loadHistory(this.token_address);
    this.timer1 = setInterval(() => {
      if (this.wallet_address) {
        this.loadEthBalance()
        this.loadErc20Balance(this.token_address);
      }
    }, 1000);

    this.timer2 = setInterval(() => {
      this.load(this.token_address);
      this.loadKline(this.token_address)
      this.loadHolder(this.token_address);
      this.loadComment(this.token_address);
      this.loadHistory(this.token_address);
    }, 6000);

  },
  unmounted() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
  }
}
</script>

<template>
  <div>
    <HeadComponent/>
    <div class="index">
      <div class="section5_1">
        <div class="wrap">
          <a href="#" class="back">Back</a>
          <div class="content1 shadow">
            <div class="imgbox"><img src="images/im1.png" alt=""></div>
            <div class="right">
              <div class="title">BNBPUMP ($ BNBP)</div>
              <div class="dec">
                <p>
                  Created by: 0x7AFF14545454856565465544aDe7
                  <span class="copy"></span>
                </p>
                <p>
                  Description: Hail the creator of Bitcoin. The real Satoshi Nakamoto. Hail Len Sassaman!
                </p>
              </div>
              <div class="list">
                <p>
                  <span class="s1">Price</span>
                  <span class="s2">0.00015 ETH</span>
                </p>
                <p>
                  <span class="s1">Market Cap</span>
                  <span class="s2">$6.76k</span>
                </p>
                <p>
                  <span class="s1">Virtual Liquidity</span>
                  <span class="s2">$14.43k</span>
                </p>
                <p>
                  <span class="s1">24H Volume</span>
                  <span class="s2">5.257 ETH</span>
                </p>
                <p>
                  <span class="s1">Rise</span>
                  <span class="s2"><b>+ 54.58%</b></span>
                </p>
              </div>
            </div>
          </div>
          <div class="content2">
            <div class="left">
              <div class="block1 shadow">
                <div class="title"><img src="images/tic1.png" alt=""><span>BNBP/ BNB</span></div>
                <div class="hd">
                  <a href="javascript:;" class="current">1m</a>
                  <a href="javascript:;">5m</a>
                  <a href="javascript:;">15m</a>
                  <a href="javascript:;">1h</a>
                  <a href="javascript:;">4h</a>
                  <a href="javascript:;">1d</a>
                </div>
                <div class="dec">
                  Time: 2024-11-30 16:00  O: 0.0{8}129 H: 0.048}148  L: 0.0{8}129 C: 0.0{8}148  Volume: 0.10
                </div>
                <div class="imgbox"><img src="images/charts.png" alt=""></div>
                <div class="date">2024-11-30 15:45</div>
              </div>
              <div class="block2">
                <div class="titHd">
                  <a href="javascript:;" class="current">
                    <img src="images/ir1.png" alt="">
                    <span>Comments</span>
                  </a>
                  <a href="javascript:;" class="">
                    <img src="images/ir2.png" alt="">
                    <span>Trading History</span>
                  </a>
                </div>
                <div class="bd shadow">
                  <div class="model">
                    <div class="bdmodel1">
                      <div class="contxt">
                        <textarea name="" id="" placeholder="write your coments"></textarea>
                        <div class="total">0 / 256</div>
                        <button type="button" class="btn">Submit</button>
                      </div>
                      <div class="items">
                        <div class="item">
                          <div class="txt">
                            <div class="tis">0x548...8574</div>
                            <div class="time">2 hour ago</div>
                          </div>
                          <div class="dec">Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales</div>
                        </div>
                        <div class="item">
                          <div class="txt">
                            <div class="tis">0x548...8574</div>
                            <div class="time">2 hour ago</div>
                          </div>
                          <div class="dec">Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales</div>
                        </div>
                        <div class="item">
                          <div class="txt">
                            <div class="tis">0x548...8574</div>
                            <div class="time">2 hour ago</div>
                          </div>
                          <div class="dec">Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales Get Noticed By Whales</div>
                        </div>
                      </div>
                      <div class="more">
                        <a href="javascript:;">Load more</a>
                      </div>
                    </div>
                  </div>
                  <div class="model">
                    <div class="bdmodel2">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0" class="">
                        <tbody><tr>
                          <th>ACCOUNT</th>
                          <th>USD</th>
                          <th>ETH</th>
                          <th>AIP</th>
                          <th>DATE</th>
                          <th>TXN</th>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td>457,414,478</td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td>457,414,478</td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td>457,414,478</td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td>457,414,478</td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td>457,414,478</td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td>457,414,478</td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        <tr>
                          <td>Oxsdss....wewq</td>
                          <td>0.2</td>
                          <td>0.0001</td>
                          <td></td>
                          <td>2 minutes ago</td>
                          <td><a href="#" class="share"></a></td>
                        </tr>
                        </tbody></table>
                      <div class="more"><a href="#">Load more</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="block1 shadow">
                <div class="hd">
                  <a href="javascript:;" class="current">Buy</a>
                  <a href="javascript:;">Sell</a>
                </div>
                <div class="bd">
                  <div class="model">
                    <div class="bl1">
                      <div class="tit">Swict to BNBP </div>
                      <a href="javascript:;" class="set"></a>
                    </div>
                    <div class="bl2">
                      <div class="lef">
                        <input type="text" placeholder="Ehter the amout" class="s1">
                        <div class="s2">Balance : 0.00BNBP</div>
                      </div>
                      <div class="ri">
                        BNB <img src="images/rif2.png" alt="">
                      </div>
                    </div>
                    <div class="bl3">
                      <div class="le">
                        <a href="javascript:;">0.1</a>
                        <a href="javascript:;">0.2</a>
                        <a href="javascript:;">0.5</a>
                        <a href="javascript:;">1</a>
                      </div>
                      <div class="ri">
                        BNB
                      </div>
                    </div>
                  </div>
                  <div class="model">
                    <div class="bl1">
                      <span></span>
                      <a href="javascript:;" class="set"></a>
                    </div>
                    <div class="bl2">
                      <div class="lef">
                        <input type="text" placeholder="Ehter the amout" class="s1">
                        <div class="s2">Balance : 0.00BNBP</div>
                      </div>
                      <div class="ri">
                        BNB <img src="images/rif5.png" alt="">
                      </div>
                    </div>
                    <div class="bl3">
                      <div class="le">
                        <a href="javascript:;">25%</a>
                        <a href="javascript:;">50%</a>
                        <a href="javascript:;">75%</a>
                        <a href="javascript:;">100%</a>
                      </div>
                      <div class="ri">
                        BNB
                      </div>
                    </div>
                  </div>
                  <a href="javascript:;" class="trade">Trade</a>
                </div>
              </div>
              <div class="block2">
                <div class="title">Bonding Curve Progress: 9.23%</div>
                <div class="line"><i></i></div>
                <div class="dec">
                  <p>
                    There are <b>571,295,680.27 BNBP </b> still available for sale in the bonding curve and there are 9.67 BNB in the bonding curve.
                  </p>
                  <p>
                    When the market cap reaches <b>$ 102,652.11</b> all the liquidity from the bonding curve will be deposited into UniSwap and burned. Progression increases as the price goes up.
                  </p>
                </div>
              </div>
              <div class="block3">
                <div class="title">Holder Distribution</div>
                <div class="con shadow">
                  <div class="tit">
                    <div class="s1">Holder</div>
                    <div class="s2">Percentage</div>
                  </div>
                  <div class="list">
                    <p>
                      <span class="s1">Oxdsar....wert</span>
                      <span class="s2">6%</span>
                    </p>
                    <p>
                      <span class="s1">Oxdsar....wert</span>
                      <span class="s2">6%</span>
                    </p>
                    <p>
                      <span class="s1">Oxdsar....wert</span>
                      <span class="s2">6%</span>
                    </p>
                    <p>
                      <span class="s1">Oxdsar....wert</span>
                      <span class="s2">6%</span>
                    </p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                  </div>
                  <div class="more">
                    <a href="#">Load more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomTxt">
        Disclaimer: Digital assets are highly speculative and involve significant risk of loss. The value of meme coins is extremely volatile, and any one who wishes to trade in any meme coin should be prepared for the possibility of losing their entire investment. AIPUMP makes no representations or warranties regarding the success or profitability of any meme coin developed on the platform. BNBUMP is a public, decentralized, and permissionless platform. Participation by any project should not be seen as an endorsement or recommendation by BNBPUMP. Users should assess their financial situation, risk tolerance, and do their own research before trading in any meme coins on the platform. BNBUMP will not be held liable for any losses, damages, or issues that may arise from trading in any meme coins developed on the platform.
      </div>


      <div class="section3 type2" >
        <div class="title"><b>BNBPUNP</b></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.iframe-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 20px;

}

.lw-chart {
  aspect-ratio: 2100 / 1300;
  border-radius: 20px !important;
  overflow: hidden;
}

.tv-lightweight-charts {
  border-radius: 20px !important;
}

#iframe {
  width: 100%;
  height: 100%;
  border: none; /* Ensures no border */
}

.dextools-widget {
  width: 100%;
  height: 400px;
  border-radius: 20px;
}

.icon_img {
  border-radius: 50% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 1 / 1 !important; /* 设置宽高比为 1:1 */
}
</style>