<template>
  <div>
    <HeadComponent/>
    <div class="index">
      <div class="section1">
        <div class="bg"><img src="images/bg1.png" alt=""></div>
        <div class="content">
          <div class="txt1">BNBPUMP</div>
          <div class="txt2">
            <p>Wolcome to </p>
            <p>BNBPUMP</p>
            <p>MEME Launchpad on BSC</p>
          </div>
          <a href="#" class="more1">How it works?</a>
          <div class="dec">You can search for your favorite TOKEN on BNBPUMP and purchase it, or you can quickly activate the token</div>
          <a href="#" class="more"></a>
        </div>
      </div>
      <div class="section2">
        <div class="wrap">
          <div class="choose">
            <div class="left">
              <h2 class="h2tit">Launched Time</h2>
              <dl>
                <dd>Launched Time</dd>
                <dd>Trading Volume</dd>
                <dd>24H Price Increase</dd>
              </dl>
            </div>
            <div class="right">
              <input type="text" placeholder="Search for token" class="words">
              <input type="button" value="" class="send">
            </div>
          </div>
          <div class="list">
            <div class="item" @click="toDetail('')">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
          </div>
          <div class="pagesize">
            <a href="#" class="border">《</a>
            <a href="#" class="border"><</a>
            <a href="#" class="current">1</a>
            <a href="#">2</a>
            <a href="#">3</a>
            <span>...</span>
            <a href="#">6</a>
            <a href="#" class="border">></a>
            <a href="#" class="border">》</a>
          </div>
        </div>
      </div>
      <div class="section3">
        <div class="title"><b>Support</b></div>
        <div class="list">
          <div class="item">
            <div class="icon"><img src="images/cir1.png" alt=""></div>
            <div class="content">
              <div class="title">PancakeSwap</div>
              <div class="dec">PancakeSwap is the leading decentralized exchange on BNB Smart Chain, with the highest trading volumes in the market</div>
            </div>
          </div>
          <div class="item">
            <div class="icon"><img src="images/cir2.png" alt=""></div>
            <div class="content">
              <div class="title">BNB Smart Chain</div>
              <div class="dec">BNB Smart Chain is a high-performance blockchain platform designed to enable the development of scalable and user-friendly decentralized applications (DApps), particularly in the decentralized finance (DeFi) space.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import axios from "@/utils/axios";
import {ElMessage} from "element-plus";
import HeadComponent from "@/components/HeadComponent.vue";
import {short_address} from "@/utils/utils";

export default {
  name: 'HomeView',
  components: {
    HeadComponent,
  },
  data: function () {
    return {
      carousels: [
        // {
        //   imageUrl: "/images/pic1.png",
        // },
        // {
        //   imageUrl: "images/pic2.png",
        // },
        // {
        //   imageUrl: "https://33bnb.meme/images/banner.jpg",
        // }
      ],
      currentPage: 1,
      totalPages: 10,
      pageSize: 12,
      token_list: [],
      token_launched: [],
      eth_price: 0,
      search_for_token: ""
    }
  },
  computed: {},
  methods: {
    short_address,
    toDetail(tokenAddress) {
      router.push('/detail?token=' + tokenAddress)
    },
    handlePageChange(num) {
      console.log('handlePageChange', num)
      this.currentPage = num
      this.load()
    },
    async load() {
      const load_resp = await axios.post('/api/token_list', {
        page: this.currentPage,
        pageSize: this.pageSize
      })
      if (load_resp.data.code === 0) {
        this.token_list = load_resp.data.data.tokens
        this.eth_price = load_resp.data.data.eth_price;
        this.totalPages = Math.ceil(load_resp.data.data.total / this.pageSize); // Calculate total pages
      }

      const token_launched_resp = await axios.post('/api/token_launched',)
      if (token_launched_resp.data.code === 0) {
        this.token_launched = token_launched_resp.data.data.tokens
        // this.eth_price = token_launched_resp.data.data.eth_price;
      }


      const load_carousel_resp = await axios.get('/api/carousel/homepage');
      if (load_carousel_resp.data.code === 0) {
        this.carousels = load_carousel_resp.data.data.carousels;
      }
    },
    async onClickSearch() {
      if (this.search_for_token) {
        const load_resp = await axios.get("/api/token/" + this.search_for_token);
        if (load_resp.data.code === 0 && load_resp.data.data.token != null) {
          await router.push('/detail?token=' + this.search_for_token)
        } else {
          ElMessage.error("Token not found")
        }
      }
    },
  },
  mounted() {
    this.load()
    this.timer1 = setInterval(() => {
      this.load()
    }, 3000)
  },
  unmounted() {
    clearInterval(this.timer1)
  }
}
</script>

<style scoped>
.el-carousel {
  width: 100%;
  height: calc(349 / 1920 * 100vw); /* 允许高度自适应 */
  border-radius: 20px;
  //aspect-ratio: 799 / 337; /* 保持宽高比 */
  //margin-bottom: 16px;
}

.el-carousel-item {
  width: 100%;
  height: calc(349 / 1920 * 100vw); /* 允许高度自适应 */

  //aspect-ratio: 799 / 337; /* 保持宽高比 */
}

.el-carousel img {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 允许高度自适应 */
  object-fit: fill; /* 保持图片比例，裁剪多余部分 */
}

.icon_img {
  border-radius: 50% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 1 / 1 !important; /* 设置宽高比为 1:1 */
}

.luanched_img {
  border-radius: 10% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 1 / 1 !important; /* 设置宽高比为 1:1 */
}
</style>
